<template>
	<section>
		<h1 class="is-size-1" v-translate>Log in</h1>
		<p v-translate>Redirecting... please wait.</p>
		<div style="height: 200px">
			<b-loading :is-full-page="false" v-model="loading"></b-loading>
		</div>
	</section>
</template>

<script>
import { mapActions, mapState } from 'vuex';
import { errorToast, translate } from '@/mixins';

// This login page doesn't actually contain login logic. It redirects to the Auth Service login page.
// When you input your credentials over there, it will redirect to this page again with a valid code that can be exchanged for tokens.
//
// Decicion logic is explained in the code below.

const START_PAGE = '/report';

export default {
	data() {
		return {
			loading: false,
		};
	},
	computed: {
		...mapState(['user', 'authorization']),
	},
	mixins: [errorToast, translate],
	methods: {
		...mapActions(['authorize', 'getTokens', 'setUserFromToken', 'getMeteorTokens']),
	},
	mounted() {
		this.loading = true;
		// Check if we're landing on this page after a redirect from Auth Service with an Authorization Code
		if (this.$route.query.code && this.$route.query.state) {
			// We have an Authorization Code, but is the state valid?
			if (this.$route.query.state === this.authorization?.state) {
				// The state is valid, get a token with the Authorization Code
				this.getTokens(this.$route.query.code)
					.then(this.setUserFromToken)
					.then(() => {
						if (this.authorization?.redirectTo) this.$router.push(this.authorization.redirectTo);
						else this.$router.push(START_PAGE);
					})
					.catch(err => {
						console.log('getTokens err:', err);
						this.errorToast(err);
						if (err.name === 'Forbidden') this.$router.push('/no-access');
					})
					.finally(() => {
						this.loading = false;
					});
			} else {
				// The state was not valid, maybe somebody is trying naughty things? Redirect them to the Auth Service
				this.authorize()
					.catch(this.errorToast)
					.finally(() => {
						this.loading = false;
					});
			}
		} else if (this.$route.query.meteorToken) {
			this.getMeteorTokens(this.$route.query.meteorToken)
				.then(this.setUserFromToken)
				.then(() => {
					//query: { redirectTo: { path: to.path, parameters: to.query } }
					if (this.$route.query?.redirectTo)
						this.$router.push({
							path: this.$route.query.redirectTo.path,
							query: this.$route.query.redirectTo.query,
						});
					else this.$router.push(START_PAGE);
				})
				.catch(err => {
					console.log('getTokens err:', err);
					this.errorToast(err);
					if (err.name === 'Forbidden') this.$router.push('/no-access');
				})
				.finally(() => {
					this.loading = false;
				});
		} else {
			// It wasn't a redirect, check if we have a valid user loaded in the app state
			if (this.user && this.user._id) {
				// Ok, we seem to have a user. The route interceptor will check if he's really valid
				this.$router.push(START_PAGE);
			} else if (this.tokens) {
				this.setUserFromToken()
					.then(() => {
						this.$router.push(START_PAGE);
					})
					.catch(err => {
						this.errorToast(err);
						if (err.name === 'Forbidden') this.$router.push('/no-access');
						else
							this.authorize()
								.catch(this.errorToast)
								.finally(() => {
									this.loading = false;
								});
					})
					.finally(() => {
						this.loading = false;
					});
			} else {
				// We don't have a valid user in the app state, redirect them to the Auth Service
				this.authorize(this.$router.currentRoute?.query?.redirectTo)
					.catch(this.errorToast)
					.finally(() => {
						this.loading = false;
					});
			}
		}
	},
};
</script>

<style lang="scss" scoped>
.signup-link {
	display: inline-block;
}
</style>
